<script setup lang="ts">
import type { Line } from "@/types";

const props = defineProps<{
  line: Line;
}>();

const avatar = (seed: string) => {
  console.log(seed);
  return `https://api.dicebear.com/9.x/thumbs/svg?seed=${seed}`;
};
</script>

<template lang="pug">
t-comment.dialogue(
  :avatar="avatar(props.line.dialogue?.from.identifier)",
  :datetime="new Date(props.line.created_at).toLocaleString()"
)
  template(#author)
    | {{ props.line.dialogue?.from.name }} -> {{ props.line.dialogue?.to.name }}
  template(#content)
    | {{ props.line.dialogue?.text }}
</template>
