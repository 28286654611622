<script setup lang="ts">
import { reactive } from "vue";
import type { ChatAction, Line } from "@/types";

const props = defineProps<{
  line: Line;
}>();

const talk_inputs = reactive<{
  [key: string]: string;
}>(
  props.line.chat_input?.chat_actions
    ?.filter((action) => action.type === "Lines::ChatActions::Talk")
    .reduce(
      (acc, action) => ({
        ...acc,
        [action.id]: "",
      }),
      {},
    ) ?? {},
);

const emit = defineEmits<{
  (e: "triggerButton", line: Line, action: ChatAction): void;
  (e: "triggerTalk", line: Line, action: ChatAction, text: string): void;
}>();
</script>

<template lang="pug">
t-card.chat-input
  .action(v-for="action in props.line.chat_input?.chat_actions", :key="action.id")
    .ui-button(v-if="action.type === 'Lines::ChatActions::Button'", @click="emit('triggerButton', props.line, action)")
      p
        | {{ action.character.name }}
      t-button
        | {{ action.prompt }} 
    .ui-talk(v-else-if="action.type === 'Lines::ChatActions::Talk'")
      p
        | {{ action.character.name }}
      t-input(v-model="talk_inputs[action.id]")
      t-button(@click="emit('triggerTalk', props.line, action, talk_inputs[action.id])")
        | 提交
    .ui-error(v-else)
      | Unknown action type: {{ action.type }}
</template>
