<script setup lang="ts">
import GameContainer from "@/components/GameContainer.vue";

import { useForm } from "@inertiajs/vue3";

import { admin_operations_game_storylet_chats_path } from "@/routes";

import type { Game, Storylet } from "@/types";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  current_game: Game;
  storylets: Storylet[];
}>();

const { t } = useI18n();

const triggerStorylet = (id: number, triggerId: string) => {
  const form = useForm({ storylet: { trigger_id: triggerId } });
  form.post(
    admin_operations_game_storylet_chats_path(props.current_game.id, id),
  );
};
</script>

<template lang="pug">
game-container
  .storylet(v-for="storylet in storylets", :key="storylet.id")
    t-descriptions.description(bordered, item-layout="vertical")
      t-descriptions-item(:label="t('storylets.id')") {{ storylet.id }}
      t-descriptions-item(:label="t('storylets.name')") {{ storylet.name }}
      t-descriptions-item(:label="t('storylets.triggered_at')") {{ storylet.triggered_at ? new Date(storylet.triggered_at).toLocaleString() : "-" }}
      t-descriptions-item(:label="t('storylets.available')") {{ storylet.available }}
      t-descriptions-item(:label="t('storylets.created_at')") {{ new Date(storylet.created_at).toLocaleString() }}
      t-descriptions-item(:label="t('storylets.updated_at')") {{ new Date(storylet.updated_at).toLocaleString() }}
      t-descriptions-item(:label="t('storylets.deleted_at')") {{ storylet.deleted_at ? new Date(storylet.deleted_at).toLocaleString() : "-" }}
      t-descriptions-item(:label="t('storylets.operations')")
        t-button.operation(
          v-for="trigger in storylet.triggers",
          :key="trigger.id",
          theme="primary",
          @click="triggerStorylet(storylet.id, trigger.id)"
        )
          | {{ t("storylets.trigger") }} {{ trigger.id }}
</template>

<style lang="stylus" scoped>
.description
  margin-top 1rem
</style>
