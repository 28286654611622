<script setup lang="ts">
import GameContainer from "@/components/GameContainer.vue";

import { router } from "@inertiajs/vue3";

import { admin_operations_game_chat_path } from "@/routes";

import type { Chat, Game } from "@/types";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  current_game: Game;
  chats: Chat[];
}>();

const { t } = useI18n();

const navigateToChat = (chat_id: number) => {
  router.visit(admin_operations_game_chat_path(props.current_game.id, chat_id));
};
</script>

<template lang="pug">
game-container
  .chat(v-for="chat in props.chats", :key="chat.id")
    t-descriptions.description(bordered, item-layout="vertical")
      t-descriptions-item(:label="t('chats.id')") {{ chat.id }}
      t-descriptions-item(:label="t('storylets.name')") {{ chat.storylet.name }}
      t-descriptions-item(:label="t('chats.finished_at')") {{ chat.finished_at ? new Date(chat.finished_at).toLocaleString() : "-" }}
      t-descriptions-item(:label="t('chats.created_at')") {{ new Date(chat.created_at).toLocaleString() }}
      t-descriptions-item(:label="t('chats.updated_at')") {{ new Date(chat.updated_at).toLocaleString() }}
      t-descriptions-item(:label="t('chats.deleted_at')") {{ chat.deleted_at ? new Date(chat.deleted_at).toLocaleString() : "-" }}
      t-descriptions-item(:label="t('chats.operations')")
        t-button(@click="navigateToChat(chat.id)")
          | {{ t("chats.inspect") }}
</template>
