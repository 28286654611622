<script setup lang="ts">
import {
  admin_operations_game_chats_path,
  admin_operations_game_storylets_path,
} from "@/routes";

import { router, usePage } from "@inertiajs/vue3";

import { ref } from "vue";

const page = usePage();

const pathname = ref(window.location.pathname);

function onPathnameChange(pathname: string) {
  router.visit(pathname);
}
</script>

<template lang="pug">
t-layout#game-container
  t-header.header
    t-head-menu(v-model="pathname", @change="onPathnameChange")
      t-menu-item(:value="admin_operations_game_storylets_path(page.props.current_game.id)", :router="{}")
        | 故事块
      t-menu-item(:value="admin_operations_game_chats_path(page.props.current_game.id)", :router="{}")
        | 对话
  t-content.content
    slot
</template>

<style scoped lang="stylus">
#game-container
  margin -20px

.content
  margin 20px
</style>
