<script setup lang="ts">
import type { Game } from "@/types";

import { router } from "@inertiajs/vue3";

import {
  admin_operations_game_path,
  admin_operations_game_storylets_path,
  admin_operations_games_path,
} from "@/routes";
import { submitForm } from "@/utils/form";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  games: Game[];
}>();

const { t } = useI18n();

function createGame() {
  submitForm("post", admin_operations_games_path(), {});
}

function inspectGame(id: string) {
  router.visit(admin_operations_game_storylets_path(id));
}

function deleteGame(id: string) {
  submitForm("delete", admin_operations_game_path(id), {});
}
</script>

<template lang="pug">
#games
  t-button(theme="primary", @click="createGame")
    | {{ t("games.create") }}
  .game(v-for="game in props.games", :key="game.id")
    t-descriptions.game(bordered, item-layout="vertical")
      t-descriptions-item(:label="t('games.id')") {{ game.id }}
      t-descriptions-item(:label="t('games.language')") {{ game.language }}
      t-descriptions-item(:label="t('games.user_id')") {{ game.user_id }}
      t-descriptions-item(:label="t('games.created_at')") {{ new Date(game.created_at).toLocaleString() }}
      t-descriptions-item(:label="t('games.updated_at')") {{ new Date(game.updated_at).toLocaleString() }}
      t-descriptions-item(:label="t('games.deleted_at')") {{ game.deleted_at ? new Date(game.deleted_at).toLocaleString() : "-" }}
      t-descriptions-item(:label="t('games.initialized_at')") {{ new Date(game.initialized_at).toLocaleString() }}
      t-descriptions-item(:label="t('games.operations')")
        t-button.operation(theme="primary", @click="inspectGame(game.id)")
          | {{ t("games.inspect") }}
        t-button.operation(v-if="!game.deleted_at", theme="danger", @click="deleteGame(game.id)")
          | {{ t("games.delete") }}
</template>

<style scoped lang="stylus">
.game
  margin-top 1rem

.operation
  margin-right 1rem
</style>
