<script setup lang="ts">
import { useForm } from "@inertiajs/vue3";

import ChatInput from "@/components/ChatInput.vue";
import Dialogue from "@/components/Dialogue.vue";
import GameContainer from "@/components/GameContainer.vue";

import { admin_operations_game_chat_line_path } from "@/routes";
import type { Chat, ChatAction, Game } from "@/types";

const props = defineProps<{
  current_game: Game;
  chat: Chat;
}>();

const triggerButton = (line: Line, action: ChatAction) => {
  const form = useForm({ line: { chat_action_id: action.id } });
  form.put(
    admin_operations_game_chat_line_path(
      props.current_game.id,
      props.chat.id,
      line.id,
    ),
  );
};

const triggerTalk = (line: Line, action: ChatAction, text: string) => {
  console.log(line, action, text);
};
</script>

<template lang="pug">
game-container
  .line(v-for="(line, index) in props.chat.lines", :key="line.id")
    chat-input(
      v-if="line.type === 'Lines::ChatInput' && index === props.chat.lines.length - 1",
      :line="line",
      @trigger-button="triggerButton",
      @trigger-talk="triggerTalk",
    )
    .selected(v-else-if="line.type === 'Lines::ChatInput'")
      | 做出选择
    dialogue(v-else-if="line.type === 'Lines::Dialogue'", :line="line")
    .error(v-else)
      | Unknown line type: {{ line.type }}
</template>

<style scoped lang="stylus">
.line
  margin-bottom: 2rem
</style>
